import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    service: "",
    subject: "",
    message: "",
    utmSource: "",
    utmMedium: "",
    utmCampaign: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Populate UTM fields
  useEffect(() => {
    const url = new URL(window.location.href);
    const utmSource = url.searchParams.get("utm_source") || "";
    const utmMedium = url.searchParams.get("utm_medium") || "";
    const utmCampaign = url.searchParams.get("utm_campaign") || "";

    setFormData((prevData) => ({
      ...prevData,
      utmSource,
      utmMedium,
      utmCampaign,
    }));
  }, []);

  // Function to validate form fields
  const validateForm = () => {
    let formErrors = {};

    if (!formData.name) {
      formErrors.name = "Enter a valid name";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.name)) {
      formErrors.name = "Name should contain only letters and spaces";
    }

    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Valid email is required";
    }

    if (!formData.phoneNumber) {
      formErrors.phoneNumber = "Phone number is required";
    } else if (!/^[6-9][0-9]{9}$/.test(formData.phoneNumber)) {
      formErrors.phoneNumber =
        "Phone number must be 10 digits and start with a digit between 6 and 9";
    }

    if (!formData.companyName) {
      formErrors.companyName = "Company name is required";
    }

    if (!formData.service) {
      formErrors.service = "Please choose a service";
    }

    if (!formData.subject) {
      formErrors.subject = "Subject is required";
    }

    if (!formData.message) {
      formErrors.message = "Message is required";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const postData = new FormData();
      postData.append("contact-name", formData.name);
      postData.append("contact-email", formData.email);
      postData.append("contact-phone", formData.phoneNumber);
      postData.append("contact-company", formData.companyName);
      postData.append("contact-subject", formData.subject);
      postData.append("contact-message", formData.message);
      postData.append("utm_source", formData.utmSource);
      postData.append("utm_medium", formData.utmMedium);
      postData.append("utm_campaign", formData.utmCampaign);

      try {
        const response = await fetch(
          "https://mediacook.in/mediacook_crm_api.php",
          {
            method: "POST",
            body: postData,
          }
        );

        if (!response.ok) {
          throw new Error("Server error: " + response.statusText);
        }

        const result = await response.json();

        if (result.status === "success") {
          setFormData({
            name: "",
            email: "",
            phoneNumber: "",
            companyName: "",
            service: "",
            subject: "",
            message: "",
            utmSource: "",
            utmMedium: "",
            utmCampaign: "",
          });

          navigate("/thank-you");
        } else {
          alert(`There was an error: ${result.message}`);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("An error occurred. Please try again later.");
      }
    }
  };

  // Handle input change and remove error when input becomes valid
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value.trim() !== "") {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  return (
    <form
      style={{ border: "1px solid #D5D5D5" }}
      onSubmit={handleSubmit}
      className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30"
    >
      <div className="row">
        {/* Name */}
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Name</label>
          <input
            type="text"
            name="name"
            className={`cs_form_field ${errors.name ? "is-invalid" : ""}`}
            placeholder="Full Name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <div className="text-danger">{errors.name}</div>}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Email */}
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Email</label>
          <input
            type="email"
            name="email"
            className={`cs_form_field ${errors.email ? "is-invalid" : ""}`}
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <div className="text-danger">{errors.email}</div>}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Phone Number */}
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">
            Phone Number
          </label>
          <input
            type="text"
            name="phoneNumber"
            className={`cs_form_field ${
              errors.phoneNumber ? "is-invalid" : ""
            }`}
            placeholder="Enter your phone number"
            value={formData.phoneNumber}
            onChange={handleChange}
            maxLength="10"
          />
          {errors.phoneNumber && (
            <div className="text-danger">{errors.phoneNumber}</div>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Company Name */}
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">
            Company Name
          </label>
          <input
            type="text"
            name="companyName"
            className={`cs_form_field ${
              errors.companyName ? "is-invalid" : ""
            }`}
            placeholder="Enter your company name"
            value={formData.companyName}
            onChange={handleChange}
          />
          {errors.companyName && (
            <div className="text-danger">{errors.companyName}</div>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Service */}
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Service</label>
          <select
            name="service"
            className={`cs_form_field ${errors.service ? "is-invalid" : ""}`}
            value={formData.service}
            onChange={handleChange}
          >
            <option value="">Choose a Service</option>
            <option value="sem">Search Engine Marketing (SEM)</option>
            <option value="seo">Search Engine Optimization (SEO)</option>
            <option value="smm">Social Media Marketing (SMM)</option>
          </select>
          {errors.service && (
            <div className="text-danger">{errors.service}</div>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Subject */}
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Subject</label>
          <input
            type="text"
            name="subject"
            className={`cs_form_field ${errors.subject ? "is-invalid" : ""}`}
            placeholder="Your subject"
            value={formData.subject}
            onChange={handleChange}
          />
          {errors.subject && (
            <div className="text-danger">{errors.subject}</div>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Message */}
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Message</label>
          <textarea
            cols={30}
            rows={10}
            name="message"
            className={`cs_form_field ${errors.message ? "is-invalid" : ""}`}
            placeholder="Write something..."
            value={formData.message}
            onChange={handleChange}
          />
          {errors.message && (
            <div className="text-danger">{errors.message}</div>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Hidden fields for UTM parameters */}
        <input type="hidden" name="utmSource" value={formData.utmSource} />
        <input type="hidden" name="utmMedium" value={formData.utmMedium} />
        <input type="hidden" name="utmCampaign" value={formData.utmCampaign} />

        {/* Submit Button */}
        <div className="col-lg-12">
          <div className="cs_height_18" />
          <button className="cs_btn cs_style_1" type="submit">
            <span>Submit</span>
            <i>
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
            </i>
          </button>
        </div>
      </div>
    </form>
  );
}
